import React, { useState } from "react";
import "./Contactform.css";

const ContactForm = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    address: "",
    message: "",
  });

  const [response, setResponse] = useState("");

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formUrl = "/.netlify/functions/sendEmail";

    try {
      const response = await fetch(formUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      const result = await response.text();
      setResponse(result);
      // Clear the form after successful submission
      if (response.ok) {
        resetForm();
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      setResponse("An error occurred, please try again.");
    }
  };

  const resetForm = () => {
    setFormData({
      name: "",
      email: "",
      phone: "",
      address: "",
      message: "",
    });
  };

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <div className="name">
          <input
            type="text"
            placeholder="Name*"
            id="name"
            name="name"
            value={formData.name}
            onChange={handleChange}
            required
          />
        </div>
        <div className="email">
          <input
            type="email"
            placeholder="Email*"
            id="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            required
          />
        </div>
        <div className="phone">
          <input
            type="tel"
            placeholder="Phone*"
            id="phone"
            name="phone"
            value={formData.phone}
            onChange={handleChange}
            required
          />
        </div>
        <div className="address">
          <input
            type="text"
            placeholder="Suburb*"
            id="address"
            name="address"
            value={formData.address}
            onChange={handleChange}
            required
          />
        </div>
        <div className="textarea">
          <textarea
            id="message"
            placeholder="Message*"
            name="message"
            value={formData.message}
            onChange={handleChange}
            required
          ></textarea>
        </div>
        <button type="submit" className="formbutton">
          Send
        </button>
      </form>
      {response && <p>{response}</p>}
    </div>
  );
};

export default ContactForm;
