import React, { useState } from "react";
import "./Pages.css";
import { Link } from "react-router-dom";
import content from "../content";
import { assets } from "../Components/Assets/assets";
import HomeCaption from "../Components/Homecaption/HomeCaption";

const Home = () => {
  const [showMoreAboutUs, setShowMoreAboutUs] = useState(false);
  const [showMoreProcess, setShowMoreProcess] = useState(false);
  const [showMoreLoan, setShowMoreLoan] = useState(false);

  return (
    <div>
      <div className="homebanner">
        <img src={assets.banner} alt="" />
        <div className="homebannertext">
          <h1>{content.homeBannerText.title}</h1>
          <h4>
            {content.homeBannerText.subtitle.split("\n").map((line, index) => (
              <React.Fragment key={index}>
                {line}
                <br />
              </React.Fragment>
            ))}
          </h4>
          <Link className="navbarlinks" to="/Contacts">
            <button type="submit" className="bannerbutton">
              {content.homeButton.titleone}
            </button>
          </Link>
        </div>
      </div>
      <div className="homecaption">
        <HomeCaption />
      </div>
      <div className="homedescription">
        <div className="aboutus">
          <div className="aboutusdescription">
            <div className="aboutustitle">{content.aboutUs.title}</div>
            <br />
            <div>{content.aboutUs.headdiscription}</div>
            <br />
            <div
              dangerouslySetInnerHTML={{ __html: content.aboutUs.subtitle }}
            />
            <div
              dangerouslySetInnerHTML={{
                __html: showMoreAboutUs
                  ? content.aboutUs.description
                  : content.aboutUs.description.split("<br /><br />")[0],
              }}
            />
            <br />
            <button
              onClick={() => setShowMoreAboutUs(!showMoreAboutUs)}
              className="readmorebutton"
            >
              {showMoreAboutUs ? "Show Less ..." : "Read More ..."}
            </button>
          </div>
          <Link className="navbarlinks" to="/Contacts">
            <button type="submit" className="bannerbutton">
              {content.homeButton.titletwo}
            </button>
          </Link>
        </div>
        <div className="process">
          <div className="processdescription">
            <div className="processtitle">{content.process.title}</div>
            <br />
            <div
              dangerouslySetInnerHTML={{
                __html: showMoreProcess
                  ? content.process.description
                  : content.process.description.split("<br />")[0],
              }}
            />
            <br />
            <button
              onClick={() => setShowMoreProcess(!showMoreProcess)}
              className="readmorebutton"
            >
              {showMoreProcess ? "Show Less ..." : "Read More ..."}
            </button>
          </div>
          <Link className="navbarlinks" to="/Contacts">
            <button type="submit" className="bannerbutton">
              {content.homeButton.titletwo}
            </button>
          </Link>
        </div>
        <div className="loan">
          <div className="loandescription">
            <div className="loantitle">{content.loan.title}</div>
            <br />
            <div
              dangerouslySetInnerHTML={{
                __html: showMoreLoan
                  ? content.loan.description
                  : content.loan.description.split("<br />")[0],
              }}
            />
            <br />
            <button
              onClick={() => setShowMoreLoan(!showMoreLoan)}
              className="readmorebutton"
            >
              {showMoreLoan ? "Show Less ..." : "Read More ..."}
            </button>
          </div>
          <br />
          <Link className="navbarlinks" to="/Contacts">
            <button type="submit" className="bannerbutton">
              {content.homeButton.titletwo}
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Home;
