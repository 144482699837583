import React from "react";
import "./Pages.css";
import content from "../content";
import firsthome from "../Components/Assets/firsthome.jpg";

const FirstHomeBuyer = () => {
  return (
    <div>
      <div className="firsthomeheader">
        <img src={firsthome} alt="" />
        <div className="firsthomebannertitle">FIRST HOME BUYER</div>
      </div>
      <div className="firsthomedescription">
        <div className="firsthomesection">
          <div className="firsthometitle">
            {content.firsthomebuyer.titleone}
          </div>
          <span>{content.firsthomebuyer.subtitleone}</span>
          <br />
          {content.firsthomebuyer.descriptionone}
          <br />
          <br />
          <a href="https://www.firsthome.gov.au/" target="blank">
            <button type="submit" className="bannerbutton">
              {content.homeLoan.button}
            </button>
          </a>
        </div>
        <div className="firsthomesection">
          <div className="firsthometitle">
            {content.firsthomebuyer.titletwo}
          </div>
          <span>{content.firsthomebuyer.subtitletwo}</span>
          <br />
          {content.firsthomebuyer.descriptiontwo}
          <br />
          <br />
          <a
            href="https://www.housingaustralia.gov.au/support-buy-home/first-home-guarantee"
            target="blank"
          >
            <button type="submit" className="bannerbutton">
              {content.homeLoan.button}
            </button>
          </a>
        </div>
        <div className="firsthomesection">
          <div className="firsthometitle">
            {content.firsthomebuyer.titlethree}
          </div>
          <span>{content.firsthomebuyer.subtitlethree}</span>
          <br />
          {content.firsthomebuyer.descriptionthree}
          <br />
          <br />
          <a
            href="https://www.qld.gov.au/housing/buying-owning-home/financial-help-concessions/transfer-duty-concessions-exemptions"
            target="blank"
          >
            <button type="submit" className="bannerbutton">
              {content.homeLoan.button}
            </button>
          </a>
        </div>
      </div>
    </div>
  );
};

export default FirstHomeBuyer;
