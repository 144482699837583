import React from "react";
import "./Pages.css";
import content from "../content";
import loan from "../Components/Assets/loan.jpg";

const SMSF = () => {
  return (
    <div>
      <div className="smsfheader">
        <img src={loan} alt="" />
        <div className="smsftitle">SMSF</div>
      </div>
      <div className="smsfcontext">
        <span>{content.smsfcontnet.titleone}</span>
        {content.smsfcontnet.descriptionone}
        <br />
        <br />
        <br />
        <span>{content.smsfcontnet.titletwo}</span>
        {content.smsfcontnet.descriptiontwo.split("\n").map((line, index) => (
          <React.Fragment key={index}>
            {line}
            <br />
          </React.Fragment>
        ))}
        <br />
        <br />
        <span>{content.smsfcontnet.titlethree}</span>
        {content.smsfcontnet.descriptionthree.split("\n").map((line, index) => (
          <React.Fragment key={index}>
            {line}
            <br />
          </React.Fragment>
        ))}
        <br />
        <br />
        <span>{content.smsfcontnet.titlefour}</span>
        {content.smsfcontnet.descriptionfour}
        <br />
        <br />
        <br />
        <span>{content.smsfcontnet.titlefive}</span>
        {content.smsfcontnet.descriptionfive}
      </div>
    </div>
  );
};

export default SMSF;
