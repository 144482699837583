import React from "react";
import "./Pages.css";
import content from "../content";
import calculator from "../Components/Assets/calculator.jpg";

const Calculator = () => {
  return (
    <div>
      <div className="claculatorheader">
        <img src={calculator} alt="" />
        <div className="calculatortitle">CALCULATORS</div>
      </div>
      <div className="calculatorcontext">
        <span> {content.calculatorcontent.title}</span>
        {content.calculatorcontent.titledescription}
      </div>
      <div className="calculators">
        <div className="mortgage">
          {content.calculatorcontent.mortgage}
          <br />
          <br />
          <a
            href={
              "https://flex.afgonline.com.au/afg/enu/calculators/LoanRepayment.html"
            }
            target="_blank"
          >
            <button type="submit" className="calculatorbutton">
              {content.calculatorbutton.mortagebutton}
            </button>
          </a>
        </div>
        <div className="loancomparision">
          {content.calculatorcontent.loancomparision}
          <br />
          <br />
          <a
            href={
              "https://flex.afgonline.com.au/afg/enu/calculators/LoanComparison.html"
            }
            target="_blank"
          >
            <button type="submit" className="calculatorbutton">
              {content.calculatorbutton.loancomparisionbutton}
            </button>
          </a>
        </div>
        <div className="interestonly">
          {content.calculatorcontent.interestonly}
          <br /> <br />
          <a
            href={
              "https://flex.afgonline.com.au/afg/enu/calculators/InterestOnlyMortgage.html"
            }
            target="_blank"
          >
            <button type="submit" className="calculatorbutton">
              {content.calculatorbutton.interestbutton}
            </button>
          </a>
        </div>
        <div className="carloan">
          {content.calculatorcontent.carloan}
          <br />
          <br />
          <a
            href={
              "https://flex.afgonline.com.au/afg/enu/calculators/Leasing.html"
            }
            target="_blank"
          >
            <button type="submit" className="calculatorbutton">
              {content.calculatorbutton.carloanbutton}
            </button>
          </a>
        </div>
        <div className="stampduty">
          {content.calculatorcontent.stampduty}
          <br />
          <br />
          <a
            href={
              "https://flex.afgonline.com.au/afg/enu/calculators/StampDuty.html"
            }
            target="_blank"
          >
            <button type="submit" className="calculatorbutton">
              {content.calculatorbutton.stampdutybutton}
            </button>
          </a>
        </div>
        <div className="borrowing">
          {content.calculatorcontent.borrowing}
          <br />
          <br />
          <a
            href={
              "https://flex.afgonline.com.au/afg/enu/calculators/BorrowingPower.html"
            }
            target="_blank"
          >
            <button type="submit" className="calculatorbutton">
              {content.calculatorbutton.borrowingbutton}
            </button>
          </a>
        </div>
        <div className="mortgageswitching">
          {content.calculatorcontent.mortgageswitching}
          <br />
          <br />
          <a
            href={
              "https://flex.afgonline.com.au/afg/enu/calculators/MortgageSwitching.html"
            }
            target="_blank"
          >
            <button type="submit" className="calculatorbutton">
              {content.calculatorbutton.mortgageswitchingbutton}
            </button>
          </a>
        </div>
      </div>
    </div>
  );
};

export default Calculator;
