import React from "react";
import "./Pages.css";
import content from "../content";
import { Link } from "react-router-dom";
import loan from "../Components/Assets/loan.jpg";

const Loans = () => {
  return (
    <div>
      <div className="loansheader">
        <img src={loan} alt="" />
        <div className="loansbannertitle">LOANS</div>
      </div>
      <div className="loansdescription">
        <div className="loanssection">
          <div className="loanstitle">{content.homeLoan.title}</div>
          {/* <span>{content.homeLoan.subtitle}</span> */}
          {/* <br /> */}
          <div
            dangerouslySetInnerHTML={{ __html: content.homeLoan.description }}
          />
          <br />
          <Link className="navbarlinks" to="/Contacts">
            <button type="submit" className="bannerbutton">
              {content.homeLoan.button}
            </button>
          </Link>
        </div>
        <div className="loanssection">
          <div className="loanstitle"> {content.carLoan.title}</div>
          {/* <span>{content.refinancing.subtitle}</span> */}
          <br />
          <div
            dangerouslySetInnerHTML={{
              __html: content.carLoan.description,
            }}
          />
          <br />
          <Link className="navbarlinks" to="/Contacts">
            <button type="submit" className="bannerbutton">
              {content.carLoan.button}
            </button>
          </Link>
        </div>
        <div className="loanssection">
          <div className="loanstitle"> {content.refinance.title}</div>
          {/* <span> {content.carLoan.subtitle}</span> */}
          <br />
          <div
            dangerouslySetInnerHTML={{ __html: content.refinance.description }}
          />
          <br />
          <Link className="navbarlinks" to="/Contacts">
            <button type="submit" className="bannerbutton">
              {content.carLoan.button}
            </button>
          </Link>
        </div>
        <div className="loanssection">
          <div className="loanstitle"> {content.personalloan.title}</div>
          {/* <span>{content.refinancing.subtitle}</span> */}
          <br />
          <div
            dangerouslySetInnerHTML={{
              __html: content.personalloan.description,
            }}
          />
          <br />
          <Link className="navbarlinks" to="/Contacts">
            <button type="submit" className="bannerbutton">
              {content.personalloan.button}
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Loans;
