import React from "react";
import "./Pages.css";
import content from "../content";

const TermsandCondition = () => {
  return (
    <div>
      <div className="termsandconditionheader">
        <div className="termsandconditiontitle">TERMS AND CONDITIONS</div>
      </div>
      <div className="termsandconditioncontext">
        {content.terms.map((item, index) => (
          <div key={index}>
            <h2>{item.title}</h2>
            <p dangerouslySetInnerHTML={{ __html: item.description }}></p>
            <br />
            <br />
          </div>
        ))}
      </div>
    </div>
  );
};

export default TermsandCondition;
