import React from "react";
import content from "../content";

const Faq = () => {
  return (
    <div>
      <div className="faqheader">
        <div className="faqtitle">FAQ</div>
      </div>
      <div className="faqcontext">
        {content.faq.map((item, index) => (
          <div key={index}>
            <span>
              {index + 1}. {item.title}
            </span>
            <p style={{ marginTop: 0 }}>{item.description}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Faq;
