import React from "react";
import "./Footer.css";
import { Link } from "react-router-dom";
import { assets } from "../Assets/assets";

const Footer = () => {
  return (
    <div className="footer">
      <div className="footertop">
        <div className="footerlogo">
          <img src={assets.logo} alt="" style={{ width: 173, height: 125 }} />
          <div className="logos">
            <img src={assets.fbaa} alt="" style={{ width: 100, height: 70 }} />
            <img src={assets.afg} alt="" style={{ width: 100, height: 50 }} />
          </div>
          Authorized Credit Representative 558065
        </div>
        <div className="footerlinks">
          <Link className="navbarlinks" to="/TermsandCondition">
            Terms and Conditions
          </Link>
          <Link className="navbarlinks" to="/PrivacyPolicy">
            Privacy Policy
          </Link>
          <Link className="navbarlinks" to="/Faq">
            FAQ
          </Link>
        </div>
        <div className="footerlinks">
          <Link className="navbarlinks" to="/Contacts">
            Contact Us
          </Link>
          <a href="tel:+610450175817">+61 450 175 817</a>
          <div className="socials">
            <a
              href="https://www.facebook.com/profile.php?id=61560013936597"
              target="blank"
            >
              <img
                src={assets.facebook}
                style={{ aspectRatio: 3 / 2 }}
                id="facebook"
                alt=""
              />
            </a>
            <a
              href="https://www.facebook.com/profile.php?id=61560013936597"
              target="blank"
            >
              <img
                src={assets.instagram}
                style={{ aspectRatio: 3 / 2 }}
                id="instagram"
                alt=""
              />
            </a>
            <a
              href="https://www.facebook.com/profile.php?id=61560013936597"
              target="blank"
            >
              <img
                src={assets.youtube}
                style={{ aspectRatio: 3 / 6 }}
                id="youtube"
                alt=""
              />
            </a>
          </div>
        </div>
        <div className="footerlinks" id="workhour">
          Contact hours:
          <br />
          <br />
          9am - 8pm
        </div>
      </div>
      <hr />
      <div className="footerbottom">
        <p>
          Future Fortune Finance © Copyright 2024, Built with{" "}
          <span style={{ color: "red" }}>&#x2764;</span> in Nepal
        </p>
      </div>
    </div>
  );
};

export default Footer;
