import React, { useRef } from "react";
import "./Navbar.css";
import logo from "../Assets/logo.png";
import menu from "../Assets/menu.svg";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";

const Navbar = () => {
  const [scroll, setScroll] = useState(false);
  const [mobileMenu, setMobileMenu] = useState(false);
  const navRef = useRef(null);

  const handleScroll = () => {
    if (window.scrollY > 50) {
      setScroll(true);
    } else {
      setScroll(false);
    }
  };

  const toggleMenu = () => {
    setMobileMenu(!mobileMenu);
  };

  const closeMenu = () => {
    setMobileMenu(false);
  };

  const handleClickOutside = (event) => {
    if (navRef.current && !navRef.current.contains(event.target)) {
      closeMenu();
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      window.removeEventListener("scroll", handleScroll);
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <nav ref={navRef} className={`navbar ${scroll ? "navbar-shrink" : ""}`}>
      <div className={`companylogo ${scroll ? "hidden" : ""}`}>
        <Link to="/" onClick={closeMenu}>
          <img src={logo} className="logo" alt="Company Logo" />
        </Link>
      </div>

      <ul className={mobileMenu ? "" : "hide-mobile-menu"}>
        <li>
          <Link className="navbarlinks" to="/" onClick={closeMenu}>
            HOME
          </Link>
        </li>
        <li>
          <Link className="navbarlinks" to="/SMSF" onClick={closeMenu}>
            SMSF
          </Link>
        </li>
        <li>
          <Link className="navbarlinks" to="/Loans" onClick={closeMenu}>
            LOANS
          </Link>
        </li>
        <li>
          <Link
            className="navbarlinks"
            to="/FirstHomeBuyer"
            onClick={closeMenu}
          >
            FIRST HOME BUYER
          </Link>
        </li>
        <li>
          <Link className="navbarlinks" to="/Calculator" onClick={closeMenu}>
            CALCULATORS
          </Link>
        </li>
        <li>
          <Link className="navbarlinks" to="/Contacts" onClick={closeMenu}>
            CONTACT US
          </Link>
        </li>
      </ul>

      <img
        src={menu}
        className="menuicon"
        onClick={toggleMenu}
        alt="Menu Icon"
      />
    </nav>
  );
};

export default Navbar;
