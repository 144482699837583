import React, { useEffect, useRef } from "react";
import "./HomeCaption.css";

const HomeCaption = () => {
  const captionRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add("visible");
          }
        });
      },
      {
        threshold: 0.1,
      }
    );

    const currentCaption = captionRef.current;
    if (currentCaption) {
      observer.observe(currentCaption);
    }

    return () => {
      if (currentCaption) {
        observer.unobserve(currentCaption);
      }
    };
  }, []);

  return (
    <div className="caption" ref={captionRef}>
      COVERING ALL YOUR FINANCIAL NEEDS
    </div>
  );
};

export default HomeCaption;
