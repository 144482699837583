import "./App.css";
import Footer from "./Components/Footer/Footer";
import Navbar from "./Components/Navbar/Navbar";
import Home from "./Pages/Home";
import SMSF from "./Pages/SMSF";
import Loans from "./Pages/Loans";
import Contacts from "./Pages/Contacts";
import FirstHomeBuyer from "./Pages/FirstHomeBuyer";
import Calculator from "./Pages/Calculator";
import TermsandCondition from "./Pages/TermsandCondition";
import PrivacyPolicy from "./Pages/PrivacyPolicy";
import Faq from "./Pages/Faq";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import ScrollToTop from "./Components/ScrollToTop";
import NotFound from "./Pages/NotFound";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <ScrollToTop />
        <Navbar />
        <main>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/SMSF" element={<SMSF />} />
            <Route path="/Loans" element={<Loans />} />
            <Route path="/Contacts" element={<Contacts />} />
            <Route path="/FirstHomeBuyer" element={<FirstHomeBuyer />} />
            <Route path="/Calculator" element={<Calculator />} />
            <Route path="/TermsandCondition" element={<TermsandCondition />} />
            <Route path="/PrivacyPolicy" element={<PrivacyPolicy />} />
            <Route path="/Faq" element={<Faq />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </main>
        <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;
