import facebookblue from "./facebookblue.svg";
import instagramblue from "./instagramblue.svg";
import youtubeblue from "./youtubeblue.svg";

import menu from "./menu.svg";

import location from "./location.svg";
import email from "./email.svg";
import phone from "./phone.svg";

import facebook from "./facebook.svg";
import instagram from "./instagram.svg";
import youtube from "./youtube.svg";

import logo from "./logo.png";
import fbaa from "./fbaa.png";
import afg from "./afg.png";

import banner from "./banner.jpg";
import contact from "./contact.jpg";

export const assets = {
  facebookblue,
  instagramblue,
  youtubeblue,

  menu,

  location,
  email,
  phone,

  facebook,
  instagram,
  youtube,

  logo,
  fbaa,
  afg,

  banner,
  contact,
};
