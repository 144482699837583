import React from "react";
import "./Pages.css";
import { Link } from "react-router-dom";
import content from "../content";

const PrivacyPolicy = () => {
  const { privacydescriptionten } = content.privacycontext;

  const contactParts = privacydescriptionten.split("Contact us");
  return (
    <div>
      <div className="privacypolicyheader">
        <div className="privacypolicytitle">PRIVACY POLICY</div>
      </div>
      <div className="privacypolicycontext">
        {content.privacycontext.privacydescription}
        <br />
        <br />
        <br />
        <span>{content.privacycontext.privacytitle}</span>
        <br />
        {content.privacycontext.privacydescriptionone}
        <br />
        <br />
        <br />
        <span>{content.privacycontext.privacytitletwo} </span>
        {content.privacycontext.privacydescriptiontwo
          .split("\n")
          .map((line, index) => (
            <React.Fragment key={index}>
              {line}
              <br />
            </React.Fragment>
          ))}
        <br />
        <br />
        <span>{content.privacycontext.privacytitlethree}</span>
        {content.privacycontext.privacydescriptionthree
          .split("\n")
          .map((line, index) => (
            <React.Fragment key={index}>
              {line}
              <br />
            </React.Fragment>
          ))}
        <br />
        <br />
        <span>{content.privacycontext.privacytitlefour}</span>
        {content.privacycontext.privacydescriptionfour
          .split("\n")
          .map((line, index) => (
            <React.Fragment key={index}>
              {line}
              <br />
            </React.Fragment>
          ))}
        <br />
        <br />
        <span>{content.privacycontext.privacytitlefive}</span>
        {content.privacycontext.privacydescriptionfive
          .split("\n")
          .map((line, index) => (
            <React.Fragment key={index}>
              {line}
              <br />
            </React.Fragment>
          ))}
        <br />
        <br />
        <span>{content.privacycontext.privacytitlesix}</span>
        {content.privacycontext.privacydescriptionsix
          .split("\n")
          .map((line, index) => (
            <React.Fragment key={index}>
              {line}
              <br />
            </React.Fragment>
          ))}
        <br />
        <br />
        <span>{content.privacycontext.privacytitleseven}</span>
        {content.privacycontext.privacydescriptionseven}
        <br />
        <br />
        <span>{content.privacycontext.privacytitleeight}</span>
        {content.privacycontext.privacydescriptioneight
          .split("\n")
          .map((line, index) => (
            <React.Fragment key={index}>
              {line}
              <br />
            </React.Fragment>
          ))}
        <br />
        <br />
        <span>{content.privacycontext.privacytitlenine}</span>
        {content.privacycontext.privacydescriptionnine}
        <br />
        <br />
        <span>
          <Link to="/Contacts">{content.privacycontext.privacytitleten}</Link>
        </span>
        {contactParts[0]}
        <span>
          <Link to="/Contacts">Contact us</Link>
        </span>
        {contactParts[1]}
      </div>
    </div>
  );
};

export default PrivacyPolicy;
