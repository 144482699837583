import React from "react";
import Contactform from "../Components/Contactform/Contactform";
import "./Pages.css";
import { assets } from "../Components/Assets/assets";

const Contacts = () => {
  return (
    <div>
      <div className="contactbanner">
        <img src={assets.contact} alt="" />
        <div className="contacttitle">CONTACT US</div>
      </div>
      <div className="contactformarea">
        <div className="contactformtitle">
          Reach out today for expert guidance on your financial journey
        </div>
        <div className="contactform">
          <div className="contactformleft">
            <Contactform />
          </div>
          <div className="contactformright">
            <div className="phone">
              <img src={assets.phone} alt="" />
              <a href="tel:+610450175817">+61 450 175 817</a>
            </div>
            <div className="address">
              <img src={assets.location} alt="" />
              <a
                href="https://maps.app.goo.gl/g76Tbi5qe4K7NBxB9"
                target="blank"
              >
                2/266 West Street, Kearneys Spring, Queensland 4350
              </a>
            </div>

            <div className="email">
              <img src={assets.email} alt="" />
              <a href="mailto:info@futurefortunefinance.com">
                info@futurefortunefinance.com
              </a>
            </div>

            <div className="contactsocial">
              <div className="socialtitle">Our Social</div>
              <div className="sociallogos">
                <a
                  href="https://www.facebook.com/profile.php?id=61560013936597"
                  target="blank"
                >
                  <img
                    src={assets.facebook}
                    style={{ aspectRatio: 3 / 2 }}
                    id="facebook"
                  />
                </a>
                <a
                  href="https://www.facebook.com/profile.php?id=61560013936597"
                  target="blank"
                >
                  <img
                    src={assets.instagram}
                    style={{ aspectRatio: 3 / 2 }}
                    id="instagram"
                  />
                </a>

                <a
                  href="https://www.facebook.com/profile.php?id=61560013936597"
                  target="blank"
                >
                  <img
                    src={assets.youtube}
                    style={{ aspectRatio: 3 / 6 }}
                    id="youtube"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3536.248643248294!2d151.93430378117313!3d-27.585819915021!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6b965b839e39df69%3A0xc0ca467fb8530bcc!2s2%2F266%20West%20St%2C%20Kearneys%20Spring%20QLD%204350%2C%20Australia!5e0!3m2!1sen!2snp!4v1719398442839!5m2!1sen!2snp"
        width="100%"
        height="400vh"
        style={{ border: 0 }}
        allowfullscreen=""
        loading="lazy"
        referrerpolicy="no-referrer-when-downgrade"
      ></iframe>
    </div>
  );
};

export default Contacts;
