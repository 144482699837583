const content = {
  // HOMEPAGE

  homeBannerText: {
    title: "Together For Better",
    subtitle:
      "For Professionalized Mortgage Services\nGet the guidance you need!",
  },

  homeButton: {
    titleone: "Book An Appointment",
    titletwo: "Learn More",
  },

  aboutUs: {
    title: "About Us",
    headdiscription:
      "Welcome to Future Fortune Finance, your trusted partner in navigating the complexities of mortgages and financing solutions in Australia. We are dedicated to helping individuals and businesses achieve their financial goals by providing expert advice and personalised service.",
    subtitle: "<strong>Our Mission</strong>",
    description: `
         At Future Fortune Finance, our mission is to empower our clients with the knowledge and support they need to make informed decisions about their mortgages. We strive to deliver tailored solutions that align with your unique financial circumstances and aspirations.
      <br /><br />
     <b>  Why Choose Future Fortune Finance? </b>
       <ul>    
      <li><strong>Expertise:</strong> Our team consists of experienced mortgage brokers who are accredited professionals in the industry. We stay updated with the latest market trends and lender policies to offer you the best advice.</li>
        <li><strong>Personalised Service:</strong> We understand that every client's financial situation is different. We take the time to listen to your needs and goals, offering personalised solutions that fit your specific requirements.</li>
        <li><strong>Access to a Wide Range of Lenders:</strong> As an independent brokerage, we have access to a diverse panel of lenders, including major banks, credit unions, and non-bank lenders. This allows us to find competitive rates and terms tailored to your needs.</li>
        <li><strong>Transparency and Trust:</strong> We believe in transparency throughout the mortgage process. From initial consultation to settlement, we keep you informed and educated, ensuring you understand each step of the journey.</li>
        <li><strong>Commitment to Privacy and Security:</strong> We prioritise the security and confidentiality of your personal information. Our practices are aligned with the Australian Privacy Principles (APPs) to safeguard your privacy.</li>
      </ul>
      Ready to take the next step towards securing your financial future? Contact Future Fortune Finance today to schedule a consultation. Let us help you turn your financial goals into reality.
    `,
  },
  process: {
    title: "How We Can Help",
    // subtitle: "Your Personal Mortgage Shopper",
    description: `
    At Future Fortune Finance, our mission is to simplify the process of securing your new home loan. We are committed to making it as easy and transparent as possible for you. Here is how we support you:
        <ul>
        <li> <strong> Comparing Home Loans: </strong> We specialise in comparing a wide array of home loan options. With access to over 50 lenders and a diverse range of products, we ensure you have all the information to make informed decisions that fit your needs.</li>
          

        <li> <strong> Explaining Your Options: </strong> Understanding your home loan options is key.
          <br /><br />
         Our dedicated team takes the time to explain the details clearly and comprehensively. Whether you're a first-time buyer, refinancing, or investing, we guide you through every step.</li>
   
        <li> <strong> Assisting with the Entire Process: </strong> From start to finish, we handle the complexities. We streamline the application process, manage paperwork, and coordinate with lenders on your behalf. Our goal is to make the process seamless and stress-free for you.</li>

        <li> <strong> Negotiating Rates on Your Behalf: </strong> Our experienced brokers negotiate competitive rates tailored to your financial situation. We leverage our relationships with lenders to ensure you get the best possible deal.</li>

        <li> <strong> Wide Variety of Products: </strong> With access to a broad range of products from major banks, credit unions, and specialty lenders, we customise solutions to match your personal circumstances and financial goals.</li>
      </ul>
    Whether you are ready to apply for a new home loan or simply want to explore your options, we are here to help. Contact us today for a personalised consultation. Let’s work together to find the perfect home loan solution for you.
    `,
  },
  loan: {
    title: "Loan Products & Programs",
    // subtitle: "Comprehensive Loan Solutions for Your Every Need",
    description: `
        At Future Fortune Finance, we offer a comprehensive range of loan products and programs tailored to meet your diverse financial needs. 
       Whether you're purchasing a home, refinancing an existing loan, or seeking specialised financing, we're here to assist you every step of the way.
           </br>
          <strong> Our Loan Products Include:</strong>
          </br>
          <strong> 1. Fixed & Variable Home Loan Products</strong>
          <ul>
      <br /><br />
            <li> <strong> Fixed Rate Home Loans: </strong> Provide stability with fixed repayments over a set period. </li>
            <li> <strong> Variable Rate Home Loans: </strong> Offer flexibility with interest rates that may fluctuate with market conditions. </li>
          </ul>
          <strong>2. Refinance of Existing Home Loans</strong>
          <ul>
            <li> Explore options to refinance your current mortgage for potentially better rates or terms. </li>
            <li>    Consolidate debts or access equity for home improvements or other financial goals. </li>
          </ul>
          <strong>3. Car Loans</strong>
          <ul>
            <li> Competitive financing options for purchasing new or used vehicles. </li>
            <li>    Flexible repayment terms and tailored solutions to fit your budget.</li>
          </ul>
          <strong>4. Personal Loans</strong>
          <ul>
            <li> Access funds for personal expenses, such as holidays, education, or unexpected costs.</li>
            <li>  Options for both secured and unsecured personal loans with competitive rates.</li>
          </ul>
          <strong>   5. Business Loans</strong>
          <ul>
            <li> Support for business growth, expansion, or operational needs.</li>
            <li> Financing solutions including working capital loans, equipment finance, and more.</li>
          </ul>
          <strong>  6. Asset Finance</strong>
          <ul>
            <li> Finance options for acquiring assets such as equipment, machinery, or technology.</li>
            <li> Structured repayment plans aligned with the lifespan and usage of the asset.</li>
          </ul>
          <strong>7. SMSF Loans (Self-Managed Super Fund Loans)</strong>
          <ul>
            <li>Financing solutions for purchasing residential or commercial property through your SMSF.</li>
            <li> Expert guidance on compliance and investment strategies within SMSF regulations.</li>
          </ul>
          Whether you're ready to apply for a specific loan product or need guidance on choosing the right option, 
          Future Fortune Finance is here to help. 
          Contact us today for a consultation and let's find the ideal loan solution to meet your needs.
      `,
  },

  //   SMSF

  smsfcontnet: {
    titleone: "What is an SMSF?",
    titletwo: "Why Consider SMSF Loans?",
    titlethree: "How Future Fortune Finance Supports Your SMSF Loan Needs?",
    titlefour:
      "Begin Maximizing Your SMSF Potential with Future Fortune Finance?",
    titlefive: "Suitability",
    descriptionone: `
       A Self Managed Super Fund is a private superannuation trust 
       structure that allows individuals to manage their own retirement savings. 
       Unlike traditional super funds, an SMSF puts you at the helm, enabling you to 
       make investment decisions that align with your unique financial goals.
      `,
    descriptiontwo: `• Property Investment:
      Access the property market through your SMSF with an SMSF loan, allowing for the purchase of residential or commercial property.
      • Diversification:
      Expand your SMSF investment portfolio beyond traditional assets, such as shares and managed funds, to include property and other growth assets.`,
    descriptionthree: `• Expert Guidance:
      Access specialized advice.
      • Tailored Solutions:
      Receive personalized loan solutions tailored to your SMSF's needs and aligned with your long-term financial objectives.
      • Comprehensive Support:
      From loan application to ongoing management, our team provides continuous support, ensuring a smooth process for your SMSF loan.`,
    descriptionfour: ` Seize the opportunity to diversify within your SMSF with 
      SMSF loans supported by the expertise and guidance of Future Fortune Finance. Contact us today.`,
    descriptionfive: `Our SMSF product is suited for Australian residents that have an
      existing SMSF or are in the process of establishing an SMSF. Suitable
      for borrowers wishing to purchase or refinance an existing residential
      investment property as a single asset.
  `,
  },

  // LOANS

  homeLoan: {
    title: "Home Loan",
    button: "Learn More",
    description: `
      <strong> Find Your Ideal Home Loan with Future Fortune Finance</strong>
      </br>
        Whether you're buying your first home, upgrading, or investing in property, Future Fortune Finance offers a range of home loan 
        solutions tailored to your needs. Our team of mortgage specialists provides personalised advice and access to a wide network of 
        lenders to help you secure the best possible terms.
        </br>
      <strong> Our Home Loan Solutions </strong>
      <ul>

      <li> <strong>  First Home Buyers: </strong> Guidance and support tailored to first-time buyers navigating the property market.</li>
      <li> <strong>  Upgrading: </strong> Options to upgrade to a larger home or a more desirable location.</li>
      <li> <strong> Investment Loans: </strong> Financing solutions for expanding your property portfolio.</li>
      <li> <strong> Construction Loans: </strong> Tailored finance for building your dream home or renovations.</li>
      </ul>


      <strong>  Why Choose Us? </strong>
      <ul>

      <li> <strong>  Extensive Lender Network:</strong> Access to over 50 lenders for competitive rates and terms.</li>
      <li> <strong>  Personalised Service:</strong> Tailored advice to match your financial goals and lifestyle.</li>
      <li>  <strong> Efficient Process:</strong> Streamlined application and approval process to save you time.</li>
      <li><strong>  Expert Guidance:</strong> Knowledgeable brokers to guide you through the home loan process.</li>
      </Ul>

      <strong>Let's Find Your Perfect Home Loan </strong>
      </br>

        Contact Future Fortune Finance today to discuss your home loan needs with our experienced team. Whether you're buying, refinancing, or investing, we're dedicated to helping you achieve your homeownership goals.
      `,
  },

  refinance: {
    title: "Refinance",
    button: "Learn More",
    description: `
      <strong> Refinance Your Home Loan with Future Fortune Finance</strong>
      </br>
       Explore refinancing options with Future Fortune Finance to potentially lower your interest rate, consolidate debts, or access equity for home improvements. Our expert brokers streamline the process, ensuring you understand all your options and guiding you to a solution that best fits your financial goals.
        </br>
      <strong> Why Refinance with Us? </strong>
      <ul>

      <li> <strong>  Lower Interest Rates: </strong> Discover potential savings with competitive rates compared to your current loan.</li>
      <li> <strong>  Debt Consolidation: </strong> Simplify your finances by combining multiple debts into one manageable repayment.</li>
      <li> <strong> Access Equity: </strong> Use your home's equity for renovations, investments, or other financial needs.</li>
      <li> <strong> Flexible Terms: </strong> Tailored solutions to suit your budget and lifestyle.</li>
      <li> <strong>Expert Guidance: </strong>  Our experienced team provides personalised advice throughout the refinancing journey.</li>
      </ul>


      <strong>  How Refinancing Works </strong>
      <ul>

      <li> <strong> Assessment:</strong> Evaluate your financial situation and goals to determine if refinancing is right for you.</li>
      <li> <strong>  Product Selection: </strong> Choose from a variety of options, including fixed and variable rate loans, tailored to your needs.</li>
      <li>  <strong> Application Process: </strong> We manage the paperwork and liaise with lenders on your behalf for a seamless experience.</li>
      <li><strong>  Settlement:</strong>Ensure a smooth transition to your new loan terms with clear explanations of changes and benefits.</li>
      </Ul>

      <strong>Start Your Refinancing Journey Today </strong>
      </br>

        Contact Future Fortune Finance for a consultation with our expert brokers. Let us help you save money, simplify your finances, and achieve your financial goals through refinancing.
      `,
  },

  personalloan: {
    title: "Personal Loans ",
    button: "Learn More",
    description: `
      <strong> Achieve Your Goals with Personal Loans from Future Fortune Finance</strong>
      </br>
       Future Fortune Finance offers flexible personal loan solutions to help you achieve your financial objectives. Whether you're consolidating debts, funding a major purchase, or covering unexpected expenses, our personalised approach ensures you find the right loan with favourable terms.
        </br>
      <strong> Our Personal Loan Options </strong>
      <ul>

      <li> <strong>  Secured Personal Loans: </strong> Lower interest rates secured against an asset like a car or property.</li>
      <li> <strong>  Unsecured Personal Loans: </strong> No collateral required for greater flexibility and quick access to funds.</li>
      <li> <strong> Debt Consolidation Loans: </strong>Combine multiple debts into a single, manageable repayment.</li>
      <li> <strong> Holiday or Special Occasion Loans: </strong>  Finance for vacations, weddings, or other significant events.</li>
      <li> <strong>Emergency Loans: </strong> Rapid access to funds for unforeseen expenses like medical bills or home repairs.</li>
      </ul>

      <strong>  Why Choose Us for Your Personal Loan? </strong>
      <ul>
      <li> <strong> Competitive Rates:</strong>  Access to competitive interest rates tailored to your financial situation.</li>
      <li> <strong>  Flexible Repayment Terms:  </strong> Customised repayment schedules to fit your budget and goals.</li>
      <li>  <strong> Efficient Process: </strong> Streamlined application and approval process for a hassle-free experience.</li>
      <li><strong> Expert Guidance:</strong> Experienced brokers providing personalised advice and support throughout the loan process.</li>
      </Ul>

      <strong>Start Achieving Your Goals Today </strong>
      </br>

        Contact Future Fortune Finance today to discuss your personal loan needs with our experienced team. Whether you're consolidating debts or planning a major purchase, we're here to help you achieve your financial goals with confidence.
      `,
  },

  carLoan: {
    title: "Car Loan",
    // subtitle: "Drive Your Dream Car with Future Fortune Finance",
    button: "Learn More",
    description: `
    <strong> Finance Your Next Vehicle with Future Fortune Finance</strong>
    </br>
    Future Fortune Finance offers competitive car loan solutions designed to get you behind the wheel of your dream vehicle. Whether you're buying new or used, our expert brokers provide personalised guidance and access to a wide range of lenders for flexible terms and competitive rates.
      </br>
    <strong> Our Car Loan Options </strong>
    <ul>
    <li> <strong>  New Car Finance: </strong> Competitive rates for purchasing a brand-new vehicle.</li>
    <li> <strong>  Used Car Loans:  </strong> No collateral required for greater flexibility and quick access to funds.Financing options for buying a quality used car.</li>
    </ul>

    <strong>  Why Finance Your Car with Us? </strong>
    <ul>
    <li> <strong> Competitive Rates:</strong> Access to competitive interest rates from a diverse panel of lenders.</li>
    <li> <strong>  Flexible Repayment Options:  </strong>Tailored repayment plans to fit your budget and financial situation.</li>
    <li>  <strong> Quick Approval:  </strong> Fast and efficient application process to get you on the road sooner.</li>
    <li><strong> Personalised Service:</strong> Dedicated support and guidance from application to settlement.</li>
    </Ul>

    <strong>Start Driving Your Dream Car Today </strong>
    </br>

      Contact Future Fortune Finance today to explore your car loan options with our experienced team. Whether you're buying new or used, we're committed to finding the best finance solution for you.


    `,
  },

  // FIRSTHOMEBUYER

  firsthomebuyer: {
    titleone: "First Home Owner Grant",
    subtitleone: " A Boost for First-Time Buyers",
    descriptionone: `
       
        At Future Fortune Finance, we assist first-time home 
        buyers in securing the First Home Owner Grant (FHOG), 
        an Australian Government initiative that provides 
        financial support to eligible buyers purchasing their 
        first home. This grant can significantly reduce the 
        cost of buying or building a new home, making homeownership 
        more accessible. Our expert brokers will guide you through the 
        eligibility criteria and application process, ensuring you maximize 
        your benefits and achieve your dream of owning a home. Contact 
        Future Fortune Finance for personalized assistance with your FHOG application. 
      `,
    titletwo: "Home Guarantee Scheme ",
    subtitletwo: " Secure Your Future Home with Confidence",
    descriptiontwo: `
       At Future Fortune Finance, we support the Australian Government's 
       Home Guarantee Scheme, designed to help eligible buyers purchase
      a home with a lower deposit. This scheme offers various guarantees, 
      such as the First Home Guarantee and the Family Home Guarantee, to make 
      homeownership more accessible and affordable. Our knowledgeable brokers 
      will guide you through the eligibility criteria and application process, 
      ensuring you receive the support you need to secure your dream home. 
      Contact Future Fortune Finance for expert advice and personalized assistance
      with the Home Guarantee Scheme.
      `,
    titlethree: "Stamp Duty Concession ",
    subtitlethree: "  Save on Your Next Property Purchase",
    descriptionthree: `
         
          At Future Fortune Finance, we help you take advantage of the Stamp Duty 
          Concession, a benefit offered by the Australian Government to reduce the 
          cost of purchasing property. This concession can significantly lower the 
          amount of stamp duty payable for eligible buyers, making homeownership more 
          affordable. Our experienced brokers will guide you through the eligibility 
          requirements and application process, ensuring you maximize your savings. 
          Contact Future Fortune Finance for expert advice on securing your stamp duty 
          concession and making your property purchase more cost-effective.
        `,
  },

  // CALCULATOR

  calculatorcontent: {
    title: "Powerful Calculators to Simplify Your Financial Decisions",
    titledescription: `Navigate borrowing and investments with confidence using powerful calculators. 
    These tools help you make informed decisions tailored to your unique situation. 
    At Future Fortune Finance, we're dedicated to guiding you towards your financial goals. 
    Explore calculators today and take the first step towards a secure financial future.
  `,
    mortgage: `Estimate your monthly mortgage payments with  Mortgage Calculator, 
    ideal for first-time homebuyers or refinancing. Get a clear view of your future financial commitments.
  `,
    loancomparision: `Choosing the right loan can be overwhelming. Loan Comparison Calculator helps you compare options side-by-side, 
    considering interest rates, terms, and fees to find the best fit for your needs.
  `,
    interestonly: `Interest Only Calculator helps you understand monthly interest payments and total costs over the interest-only period. 
    Use it to see how an interest-only loan fits into your financial strategy.
  `,
    carloan: `Planning to buy a car? Car Loan Calculator helps you estimate monthly payments, total interest, 
    and overall loan costs based on amount,
     interest rate, and term. Ensure clarity on your financial commitments before purchasing.
  `,
    stampduty: `Estimate your property's stamp duty with our calculator
     based on its value and location, ensuring clarity on additional costs for informed planning.
  `,
    borrowing: `Borrowing Power Calculator estimates the maximum amount you can borrow based on your income, expenses, 
    and other financial details, providing clarity for your borrowing decisions.
  `,
    mortgageswitching: `Mortgage Switching Calculator helps you evaluate potential savings and costs when switching your mortgage
     to a new lender or product, ensuring informed decisions about your financial strategy.
  `,
  },

  calculatorbutton: {
    mortagebutton: " Mortgage Calculator",
    loancomparisionbutton: " Loan Comparision Calculator",
    interestbutton: " Interest Only Calculator",
    carloanbutton: " Car Loan Calculator",
    stampdutybutton: " Stamp Duty Calculator",
    borrowingbutton: " Borrowing Calculator",
    mortgageswitchingbutton: " Mortgage Switching Calculator",
    loanrepaymentbutton: " Loan Repayment Calculator",
    interestonlymortgagebutton: " Interest Only Mortage Calculator",
  },

  // TERMSANDCONDITION

  terms: [
    {
      title: "Overview",
      description: `
        We hold the necessary mortgage broking experience and qualifications in accordance with the National Consumer 
        Credit Protection Act, 2009 to provide you with assistance. We are required to meet specific competency standards 
        relating to educational and professional development. You can be confident that we are held accountable to not 
        only our organisation’s high ethical standards / values, but also have a responsibility to maintain the regulatory 
        standards that are set by both Commonwealth and State governments. Our mission is to ensure we offer our clients the 
        best service and most appropriate products to suit their individual needs through our professionalism and attention 
        to detail. Ultimately, our goal is to ensure applicants are provided with a loan that meets their objectives. We 
        guarantee to listen to your needs and your instructions, ensuring that there is collaborative agreement through each 
        step of the finance application process. Our relationships and alliances with like-minded quality organisations ensure 
        we are positioned to offer the best quality service as well as offering complementary financial services where appropriate.
      `,
    },
    {
      title: "Suitability of Loans to Your Financial Objectives",
      description: `
        By law, before entering into a specific credit contract, we are obliged to conduct a Preliminary 
        Credit Assessment to determine what kind of loans would be suitable for you. In consultation with you, 
        we will explore and discuss with you your financial situation, financial objectives and borrowing needs before 
        we determine which loan product may suit your requirements. 

        For the purposes of the Preliminary Credit Assessment, we will need to ask you some questions in order to assess whether 
        the loan or lease is not unsuitable. The law requires us to:
        <ul>
          <li>make reasonable inquiries about your requirements and objectives;</li>
          <li>make reasonable inquiries about your financial situation; and</li>
          <li>take reasonable steps to verify that financial situation.</li>
        </ul>

        The assessment will be conducted prior to recommendation of a particular product. 
        The assessment will involve collection and verification of financial information to determine the 
        appropriate loan amount and the loan costs associated with entering a credit contract. This will ensure that 
        your circumstances will be assessed appropriately and that the options suggested will not place you in financial hardship. 
        Once completed, this Preliminary Credit Assessment is only valid for 90 days. A copy of the Preliminary Credit Assessment 
        will be available to you, on request - this will be available up to 7 years after we provide you with credit assistance.
        Prior to the Preliminary Credit Assessment being conducted, we may provide you with Product summaries that highlight various 
        key features and benefits of the product. We may also provide you with Product Comparison documents that allow you to compare 
        the features and benefits of each product and assess the suitability to your requirements.
      `,
    },
    {
      title: "Fees, Charges, Commission and Disclosures",
      description: `
        <strong>Fees payable by you to third parties:</strong>
        <p>When the credit application (loan) is submitted, you may need to pay the lender's application fee, valuation fees, 
        or other fees that are associated with the loan application process, even if the loan is ultimately unsuccessful.</p>

        <strong>Fees payable by you to the licensee:</strong>
        <p>If a fee is payable by you, this will be disclosed in a Credit Quote that will be provided to you. 
        If a Credit Quote is not supplied, this will indicate that we do not charge consumers any fees.</p>

        <strong>Payments received by the licensee:</strong>
        <p>Please take notice that the licensee may receive fees, commissions, or financial rewards from Lenders or Lessors 
        in connection with any finance we arrange for you. These fees are not payable by you. The commission / brokerage amount 
        depends on the amount of the finance and may vary from product to product. We can provide you with information about a 
        reasonable estimate of those commissions and how the commission is worked out if you require.
        Commissions are paid based on a percentage of the loan balance that is drawn down which in most cases will be net of 
        any amounts you hold in an offset account. The way commissions are calculated and paid to us by lenders may vary. 
        By following the responsible lending requirements, we will ensure the loan recommended to you is not unsuitable for your 
        situation and objectives.</p>

        <strong>Fees payable by the licensee to third parties:</strong>
        <p>We may pay fees to call centre companies, real estate agents, accountants, or lawyers and others for referring you to us. 
        These referral fees are generally small amounts in accordance with usual business practice. These are not fees payable by you. 
        On request you can obtain a reasonable estimate of the amount of the fee and how it is worked out. From time to time, we may also 
        remunerate other parties through payments, rewards or benefits.</p>
      `,
    },
    {
      title: "Tiered Servicing Arrangements",
      description: `
        Through your broker’s relationships with lenders they may have access to tiered servicing arrangements. 
        The benefits of this access to these arrangements can include faster processing, better information or greater levels of 
        assistance provided for obtaining loan approval. Your broker will advise you of any tiered service arrangements that are in 
        place with a particular lender that they have proposed at the time recommendations are made.
        Access to this program is not based solely on the volume of new or existing lending your broker’s customers have with each 
        respective lender and does not entitle them to any additional commissions outside of what they have disclosed to you, any 
        additional payments or preferential customer discounts.
      `,
    },
  ],

  // PRIVACYPOLICY
  privacycontext: {
    privacydescription: ` Future Fortune Finance (hereinafter referred to as "we", "us", or "our") is committed to 
    protecting your privacy and handling your personal information in accordance with the Privacy Act 1988 (Cth) 
    and the Australian Privacy Principles (APPs). This
        Privacy Policy outlines how we collect, use, disclose, and safeguard
        your information when you visit our website and engage with our
        financial advisory services. By using our services, you consent to the
        practices described in this policy.
  `,
    privacytitle: "Information We Collect",
    privacydescriptionone:
      "We may collect and process the following types of information:",

    privacytitletwo: "1. Personal Information:",
    privacydescriptiontwo: `• Name
         • Contact details (email address, phone number, mailing address)
         • Date of birth Identification documents (e.g., driver's license, passport)
        • Financial information (e.g., bank account details, credit card information)
        • Employment details Investment preferences and history`,

    privacytitlethree: "2. Non-Personal Information:",
    privacydescriptionthree: `• Browser type and version
        • Operating system 
        • IP address 
        • Cookies and tracking technologies
        • Usage data (e.g., pages visited, time spent on the site)`,

    privacytitlefour: "How We Use Your Information",
    privacydescriptionfour: `We use the information we collect for various
        purposes, including: 
        • To provide and manage our financial advisory services
        • To personalize your experience on our website 
        • To communicate with you, including responding to inquiries and sending updates
        • To process transactions and manage accounts
        • To comply with legal and regulatory requirements
        • To improve our website and services
        • To conduct market research and analysis`,

    privacytitlefive: "Disclosure of Your Information",
    privacydescriptionfive: ` We may share your information with third parties in the following circumstances: 
        • With service providers who assist us in operating our website and providing our services
        • With financial institutions, brokers, and other entities involved in your financial transactions
        • As required by law or regulation, such as to comply with a subpoena or similar legal process
        • To protect the rights, property, or safety of Future Fortune Finance, our clients, or others
        • With your consent or at your direction`,

    privacytitlesix: "Data Security",
    privacydescriptionsix: ` We implement a variety of security measures to protect your personal information. These measures include:
            • Encryption of sensitive data
            • Secure access controls
            • Regular security assessments and audits
            • Staff training on data protection principles`,

    privacytitleseven: "Cookies and Tracking Technologies",
    privacydescriptionseven: ` Our website uses cookies and similar tracking technologies 
    to enhance your browsing experience. You can control the use of cookies through your browser 
    settings. However, disabling cookies may affect the functionality of our website.`,

    privacytitleeight: "Your Rights and Choices",
    privacydescriptioneight: ` You have the following rights regarding your personal information:
            • Access: You can request access to the personal information we hold about you.
            • Correction: You can request corrections to any inaccurate or incomplete information.
            • Deletion: You can request the deletion of your personal information, subject to legal and regulatory requirements.
            • Opt-Out: You can opt-out of receiving marketing communications from us.`,

    privacytitlenine: "Changes to This Privacy Policy",
    privacydescriptionnine: ` We may update this Privacy Policy from 
    time to time to reflect changes in our practices or legal
     requirements. We will notify you of any significant changes by 
     posting the updated policy on our website. Your continued use of
      our services after such changes will constitute your acceptance of
       the updated policy.
`,

    privacytitleten: "Contact Us",
    privacydescriptionten: `If you have any questions or concerns about this Privacy Policy or our data practices, please  
contact us
  This Privacy Policy is effective as of 2024 
`,
  },

  // FAQ

  faq: [
    {
      title: "What is a mortgage broker?",
      description: `
        A mortgage broker is a qualified professional who helps individuals 
        and businesses find suitable mortgage products from a panel of lenders. 
        They provide advice and assistance throughout the mortgage application process.
      `,
    },
    {
      title: "Why use a mortgage broker?",
      description: `
        Mortgage brokers have access to a wide range of lenders, including major banks, 
        credit unions, and non-bank lenders. They can help you compare different loan 
        products and find competitive rates tailored to your needs.
      `,
    },
    {
      title: "How does a mortgage broker get paid?",
      description: `
        Mortgage brokers are usually paid commissions by lenders for successful loans, 
        and some may charge fees to borrowers. These arrangements are disclosed upfront, 
        ensuring transparency in the transaction.
      `,
    },
    {
      title: "What documents are required to apply for a mortgage?",
      description: `
        Typical documents include proof of income (pay slips, tax returns), 
        identification (passport, driver's license), bank statements, and details 
        of existing debts. Requirements may vary among lenders.
      `,
    },
    {
      title: "How much can I borrow for a mortgage?",
      description: `
        The amount you can borrow depends on factors such as your income, expenses, 
        credit history, and the lender's criteria. Mortgage brokers can assess your 
        financial situation to determine a suitable borrowing amount.
      `,
    },
    {
      title: "What types of mortgages are available?",
      description: `
        Common mortgage types include variable rate mortgages, fixed-rate mortgages, 
        interest-only loans, and package loans that bundle additional financial products 
        with the mortgage.
      `,
    },
    {
      title: "How long does the mortgage process take?",
      description: `
        The timeline varies but typically takes a few weeks from application to settlement. 
        Factors affecting timing include lender processing times, property valuations, 
        and document verification.
      `,
    },
    {
      title: "Can I get a mortgage with bad credit?",
      description: `
        It's possible to obtain a mortgage with less-than-perfect credit, but options 
        may be limited. Some lenders specialise in bad credit mortgages, and a mortgage 
        broker can help you find suitable options.
      `,
    },
    {
      title: "What is mortgage pre-approval?",
      description: `
        Mortgage pre-approval involves a lender assessing your financial situation and 
        determining the maximum amount you can borrow. It gives you confidence when house 
        hunting and strengthens your offer when making an offer on a property.
      `,
    },
    {
      title: "What is refinancing?",
      description: `
        Refinancing is the process of replacing an existing mortgage with a new loan, 
        typically to obtain better terms, lower interest rates, or to access equity 
        in the property. You might consider refinancing if interest rates have decreased 
        since you took out your original loan, if your credit score has improved, or 
        if you want to consolidate debts or renovate your home.
      `,
    },
    {
      title: "What are the costs associated with refinancing?",
      description: `
        Costs may include application fees, valuation fees, discharge fees for your 
        current mortgage, and potentially legal fees. Your mortgage broker can provide 
        a breakdown of these costs upfront.
      `,
    },
    {
      title: "How is borrowing capacity calculated?",
      description: `
        Borrowing capacity is determined by factors such as your income, expenses, 
        existing debts, credit history, and the lender's assessment criteria. A mortgage 
        broker can help you understand how these factors influence your borrowing capacity.
      `,
    },
    {
      title: "Can I increase my borrowing capacity?",
      description: `
        You may be able to increase your borrowing capacity by reducing existing debts, 
        increasing your income, or improving your credit score. Your mortgage broker can 
        provide guidance on steps to enhance your borrowing capacity.
      `,
    },
    {
      title: "What is an offset account?",
      description: `
        An offset account is a transaction account linked to your mortgage. The balance 
        in this account offsets the principal loan amount, reducing the interest charged 
        on your mortgage.
      `,
    },
    {
      title: "How does an offset account benefit me?",
      description: `
        By reducing the interest payable on your mortgage, an offset account can potentially 
        shorten your loan term and save you thousands of dollars in interest payments 
        over time.
      `,
    },
    {
      title: "Are there fees associated with an offset account?",
      description: `
        Some lenders may charge fees for offset accounts, such as monthly account-keeping 
        fees. However, the interest savings typically outweigh these costs.
      `,
    },
    {
      title: "What is a redraw facility?",
      description: `
        A redraw facility allows you to withdraw additional repayments made on your mortgage. 
        It provides flexibility by allowing you to access these funds if needed, such as for 
        emergencies or renovations.
      `,
    },
    {
      title: "How does a redraw facility work?",
      description: `
        Funds deposited into your mortgage account beyond the required repayments can be 
        withdrawn through the redraw facility, subject to any minimum withdrawal amounts 
        and terms set by your lender.
      `,
    },
    {
      title: "Are there any restrictions on using a redraw facility?",
      description: `
        Some lenders may have minimum redraw amounts or limit the frequency of withdrawals. 
        It's essential to review the terms and conditions of your loan with your mortgage broker.
      `,
    },
    {
      title: "What is considered a first-time home buyer?",
      description: `
        In Australia, a first-time home buyer is someone who has never owned residential 
        property in Australia or who has never received a first home buyer grant or duty 
        concession in any state or territory of Australia.
      `,
    },
    {
      title:
        "Am I eligible for any government incentives as a first-time home buyer?",
      description: `
        First-time home buyers may be eligible for various government incentives, such as 
        the First Home Owner Grant (FHOG) or stamp duty concessions. Eligibility criteria 
        vary by state and territory.
      `,
    },
    {
      title: "How much deposit do I need as a first-time home buyer?",
      description: `
        Generally, first-time home buyers need a deposit of at least 5% to 20% of the property's 
        purchase price, depending on the lender's requirements and whether they qualify for 
        lenders mortgage insurance (LMI).
      `,
    },
    {
      title: "What steps should I take as a first-time home buyer?",
      description: `
        As a first-time home buyer, it's essential to assess your borrowing capacity, 
        research available government incentives, compare mortgage options with your broker, 
        and conduct thorough inspections of potential properties.
      `,
    },
  ],
};

export default content;
